import React, {Component, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import "./Personal/assets/css/style.css";
import "./Personal/assets/vendor/bootstrap/css/bootstrap.min.css" ;
import "./Personal/assets/vendor/bootstrap-icons/bootstrap-icons.css" ;
import "./Personal/assets/vendor/boxicons/css/boxicons.min.css" ;
import "./Personal/assets/vendor/glightbox/css/glightbox.min.css" ;
import "./Personal/assets/vendor/remixicon/remixicon.css" ;
import "./Personal/assets/vendor/swiper/swiper-bundle.min.css" ;




export default function Home(props) {
    return(
        <header id="header">
          <div class="container">
      
            <h1><a href="index.html">Klay Gacha Machine</a></h1>
            <a href="index.html" class="mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"/></a>
            <h2>가장 효율적인 <span>클레이튼 기반의 NFT 에코시스템</span></h2>
      
            <nav id="navbar" class="navbar">
              <ul>
                <li><button type="submit"><a class="nav-link active" href="https://github.com/Planet-Us/Klay_Gacha_Machine">Explore Project V1</a></button></li>
                <li><button type="submit"><a class="nav-link active" href="https://github.com/Planet-Us/Klay-Gacha-MachineV2">Explore Project V2</a></button></li>
                <li><button type="submit"><a class="nav-link" href="https://docs.klaygachamachine.com/">Explore Develop Docs</a></button></li>
              </ul>
            </nav>
      
            <div class="social-links">
              <a href="https://discord.gg/77JBtm29kp" class="discord"><i class="bi bi-discord"></i></a>
              <a href="https://medium.com/@eklee808" class="medium"><i class="bi bi-medium"></i></a>
            </div>
      
          </div>
        </header>
    );

}